import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import AuthModal from "../auth/AuthModal";
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout } from "../../../redux/auth/Action";
import logo from "./logo.png";
import laminate_preview_1 from "./laminate_preview_1.jpg";
import laminate_preview_2 from "./laminate_preview_2.jpeg";
import wardrobe_preview_1 from "./wardrobe_preview_1.jpg";
import wardrobe_preview_2 from "./wardrobe_preview_2.jpg";
import wallpaper_preview_1 from "./wallpaper_preview_1.jpg";
import wallpaper_preview_2 from "./wallpaper_preview_2.jpg";
import bathroom_preview_1 from "./bathroom_preview_1.jpg";
import bathroom_preview_2 from "./bathroom_preview_2.jpg";
import kitchen_preview_1 from "./kitchen_preview_1.jpg";
import kitchen_preview_2 from "./kitchen_preview_2.jpg";
import outdoor_preview_1 from "./outdoor_preview_1.jpg";
import outdoor_preview_2 from "./outdoor_preview_2.jpg";
import flooring_preview_1 from "./flooring_preview_1.jpg";
import flooring_preview_2 from "./flooring_preview_2.jpg";

const navigation = {
  categories: [
    {
      id: 'laminates',
      name: 'LAMINATES',
      featured: [
        {
          name: 'New Arrivals',
          href: '/laminates/shop_by_look/wood_laminates',
          imageSrc: laminate_preview_1,
          imageAlt: 'ADM Laminates',
        },
        {
          name: 'New Arrivals',
          href: '/laminates/shop_by_look/solid_laminates',
          imageSrc: laminate_preview_2,
          imageAlt:
            'ADM Laminates',
        },
      ],
      sections: [
        {
          id: 'shop_by_look',
          name: 'Shop by Look',
          items: [
            { id: 'wood_laminates', name: 'Wood Laminates', href: '#' },
            { id: 'marble_and_stone_laminates', name: 'Marble and Stone Laminates', href: '#' },
            { id: 'solid_laminates', name: 'Solid Laminates', href: '#' },
            { id: 'fluted_laminates', name: 'Fluted Laminates', href: '#' },
          ],
        },
        {
          id: 'shop_by_finish',
          name: 'Shop by Finish',
          items: [
            { id: 'high_gloss', name: 'High Gloss', href: '#' },
            { id: 'matte_gloss', name: 'Matte Gloss', href: '#' },
            { id: 'suede_finish', name: 'Suede Finish', href: '#' },
          ],
        },
      ],
    },
    {
      id: 'kitchen',
      name: 'KITCHEN',
      featured: [
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: kitchen_preview_1,
          imageAlt: 'ADM Kitchen',
        },
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: kitchen_preview_2,
          imageAlt:
            'ADM Kitchen',
        },
      ],
      sections: [
        {
          id: 'shop_by_look',
          name: 'Shop by Look',
          items: [
            { id: '1mm_laminates', name: '1 mm Laminates', href: '#' },
            { id: 'wood_laminates', name: 'Wood Look Laminates', href: '#' },
            { id: 'solid_laminates', name: 'Solid Color Laminates', href: '#' },
            { id: 'marble_look_laminates', name: 'Marble Look Laminates', href: '#' },
          ],
        },
        {
          id: 'counter_tops',
          name: 'Counter Tops',
          items: [
            { id: 'granite_slabs', name: 'Granite Slabs', href: '#' },
          ],
        },
      ],
    },
    {
      id: 'flooring',
      name: 'FLOORING',
      featured: [
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: flooring_preview_1,
          imageAlt: 'ADM Flooring',
        },
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: flooring_preview_2,
          imageAlt:
            'ADM Flooring',
        },
      ],
      sections: [
        {
          id: 'shop_by_look_1',
          name: 'Shop by Look',
          items: [
            { id: 'marble_look', name: 'Marble Look', href: '#' },
            { id: 'granite', name: 'Granite', href: '#' },
          ],
        },
        {
          id: 'shop_by_finish_1',
          name: 'Shop by Finish',
          items: [
            { id: 'matte_finish', name: 'Matte Finish', href: '#' },
            { id: 'glossy_finish', name: 'Glossy Finish', href: '#' },
          ],
        },
      ],
    },
    {
      id: 'wardrobe',
      name: 'WARDROBE',
      featured: [
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: wardrobe_preview_1,
          imageAlt: 'ADM Wardrobe',
        },
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: wardrobe_preview_2,
          imageAlt:
            'ADM Wardrobe',
        },
      ],
      sections: [
        {
          id: 'wardrobe_look',
          name: 'Wardrobe Look',
          items: [
            { id: 'wood_laminates', name: 'Wood Laminates', href: '#' },
            { id: 'marble_and_stone_laminates', name: 'Marble and Stone Laminates', href: '#' },
            { id: 'solid_laminates', name: 'Solid Laminates', href: '#' },
            { id: 'fluted_laminates', name: 'Fluted Laminates', href: '#' },
          ],
        },
        {
          id: 'wardrobe_finishes',
          name: 'Wardrobe Finishes',
          items: [
            { id: 'high_gloss', name: 'High Gloss', href: '#' },
            { id: 'matte_gloss', name: 'Matte Gloss', href: '#' },
            { id: 'suede_finish', name: 'Suede Finish', href: '#' },
          ],
        },
      ],
    },
    {
      id: 'bathroom',
      name: 'BATHROOM',
      featured: [
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: bathroom_preview_1,
          imageAlt: 'ADM Bathroom',
        },
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: bathroom_preview_2,
          imageAlt:
            'ADM Bathroom',
        },
      ],
      sections: [
        {
          id: 'bathroom_upcoming',
          name: 'We are updating, Stay Tuned !!',
          items: [],
        },
      ],
    },
    {
      id: 'wallpaper',
      name: 'WALLPAPER',
      featured: [
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: wallpaper_preview_1,
          imageAlt: 'ADM Wallpapers',
        },
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: wallpaper_preview_2,
          imageAlt:
            'ADM Wallpapers',
        },
      ],
      sections: [
        {
          id: 'shop_by_application',
          name: 'Shop by Application',
          items: [
            { id: 'bedroom_wallpaper', name: 'Bedroom Wallpaper', href: '#' },
            { id: 'kids_wallpaper', name: 'Kids Room Wallpaper', href: '#' },
            { id: 'livingroom_wallpaper', name: 'Living Room Wallpaper', href: '#' },
            { id: 'drawingroom_wallpaper', name: 'Drawing Room Wallpaper', href: '#' },
            { id: 'poojaroom_wallpaper', name: 'Pooja Room Wallpaper', href: '#' },
          ],
        },
        {
          id: 'shop_by_look_2',
          name: 'Shop by Look',
          items: [
            { id: 'abstract_wallpaper', name: 'Abstract Wallpaper', href: '#' },
            { id: 'classic_wallpaper', name: 'Classic Wallpaper', href: '#' },
            { id: 'geometric_wallpaper', name: 'Geometric Wallpaper', href: '#' },
            { id: 'fauxfinish_wallpaper', name: 'Faux Finish Wallpaper', href: '#' },
            { id: 'brick_wallpaper', name: 'Brick Wallpaper', href: '#' },
            { id: 'wood_wallpaper', name: 'Wood Wallpaper', href: '#' },
          ],
        },
      ],
    },
    {
      id: 'outdoor',
      name: 'OUTDOOR',
      featured: [
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: outdoor_preview_1,
          imageAlt: 'ADM Outdoor',
        },
        {
          name: 'New Arrivals',
          href: '#',
          imageSrc: outdoor_preview_2,
          imageAlt: 'ADM Outdoor',
        },
      ],
      sections: [
        {
          id: 'shop_by_material',
          name: 'Shop by Material',
          items: [
            { id: 'granite_stone', name: 'Granite Stone', href: '#' },
          ],
        },
      ],
    },
    
  ],
  pages: [
    { name: 'Company', href: '#' },
    { name: 'Stores', href: '#' },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector((store) => store);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const location=useLocation();
  const jwt=localStorage.getItem("jwt");
  
  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = (event) => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpenAuthModal(true);
  };

  const handleClose = () => {
    setOpenAuthModal(false);
  };

  const handleCategoryClick = (category, section, item, close) => {
    navigate(`/${category.id}/${section.id}/${item.id}`);
    close();
  };

  useEffect(()=>{
    if(jwt){
      dispatch(getUser(jwt))
    }
  
  },[jwt, auth.jwt])

  useEffect(() => {
    if (auth.user){ 
      handleClose();
    }
    if(location.pathname==="/login" || location.pathname==="/register"){
      navigate(-1)
    }
  }, [auth.user]);

  const handleLogout = () => {
    dispatch(logout());
    handleCloseUserMenu();
  };

  const handleMyOrderClick=()=>{
    handleCloseUserMenu()
    auth.user?.role==="ROLE_ADMIN"?navigate("/admin"):navigate("/account/order")
    
  }

  return (
    <div className="bg-white pb-2">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-gray-200">
                    <Tab.List className="-mb-px flex space-x-8 px-4">
                      {navigation.categories.map((category) => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "border-indigo-600 text-indigo-600"
                                : "border-transparent text-gray-900",
                              "flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium border-none"
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {navigation.categories.map((category) => (
                      <Tab.Panel
                        key={category.name}
                        className="space-y-10 px-4 pb-8 pt-10"
                      >
                        <div className="grid grid-cols-2 gap-x-4">
                          {category.featured.map((item) => (
                            <div
                              key={item.name}
                              className="group relative text-sm"
                            >
                              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                <img
                                  src={item.imageSrc}
                                  alt={item.imageAlt}
                                  className="object-cover object-center"
                                />
                              </div>
                              <a
                                href={item.href}
                                className="mt-6 block font-medium text-gray-900"
                              >
                                <span
                                  className="absolute inset-0 z-10"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                              <p aria-hidden="true" className="mt-1">
                                Shop now
                              </p>
                            </div>
                          ))}
                        </div>
                        {category.sections.map((section) => (
                          <div key={section.name}>
                            <p
                              id={`${category.id}-${section.id}-heading-mobile`}
                              className="font-medium text-gray-900"
                            >
                              {section.name}
                            </p>
                            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                            <ul
                              role="list"
                              aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                              className="mt-6 flex flex-col space-y-6"
                            >
                              {section.items.map((item) => (
                                <li key={item.name} className="flow-root">
                                  <p className="-m-2 block p-2 text-gray-500">
                                    {"item.name"}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>

                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  <div className="flow-root">
                    <a
                      href="/"
                      className="-m-2 block p-2 font-medium text-gray-900"
                    >
                      Sign in
                    </a>
                  </div>
                </div>

                <div className="border-t border-gray-200 px-4 py-6">
                  <a href="/" className="-m-2 flex items-center p-2">
                    <img
                      src="https://tailwindui.com/img/flags/flag-canada.svg"
                      alt=""
                      className="block h-auto w-5 flex-shrink-0"
                    />
                    <span className="ml-3 block text-base font-medium text-gray-900">
                      CAD
                    </span>
                    <span className="sr-only">, change currency</span>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative bg-white">
        {/* <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          Get free delivery on orders over $100
        </p> */}

        <nav aria-label="Top" className="mx-auto">
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center px-11">
              <button
                type="button"
                className="rounded-md bg-white p-2 text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex flex-wrap justify-center lg:ml-0">
                <Link to="/">
                  <span className="sr-only">ArchDesignMart</span>
                  <img
                    src={logo}
                    alt="Shopwithzosh"
                    className="h-14 w-14 mr-2"
                  />
                </Link>
                  <span className="ml-2 mt-3 font-medium text-xl text-gray-700 group-hover:text-gray-800">
                    ArchDesign<strong>Mart</strong>
                  </span>
                  <span className="ml-2 mt-3 font-medium text-xs text-gray-700 group-hover:text-gray-800">Beta</span>
              </div>

              <div className="ml-auto flex items-center justify-right">

                {/* Home */}
                <div className="flex lg:ml-6">
                    <Button
                      onClick={() => navigate("/")}
                      className="group -m-2 flex items-center p-2"
                    >
                      <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                        Home
                      </span>
                    </Button>
                </div>

                {/* Contact Us */}
                <div className="flex lg:ml-6">
                    <Button
                      onClick={() => navigate("/")}
                      className="group -m-2 flex items-center p-2"
                    >
                      <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                        Contact Us
                      </span>
                    </Button>
                </div>

                {/* About Us */}
                <div className="flex lg:ml-6">
                    <Button
                      onClick={() => navigate("/")}
                      className="group -m-2 flex items-center p-2"
                    >
                      <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                        About Us
                      </span>
                    </Button>
                </div>

                {/* Services */}
                <div className="flex lg:ml-6">
                    <Button
                      onClick={() => navigate("/")}
                      className="group -m-2 flex items-center p-2"
                    >
                      <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                        Services
                      </span>
                    </Button>
                </div>

                {/* Blog */}
                <div className="flex lg:ml-6">
                    <Button
                      onClick={() => navigate("/")}
                      className="group -m-2 flex items-center p-2"
                    >
                      <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                        Blog
                      </span>
                    </Button>
                </div>

                {/* Brands */}
                <div className="flex lg:ml-6">
                    <Button
                      onClick={() => navigate("/brands")}
                      className="group -m-2 flex items-center p-2"
                    >
                      <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                        Brands
                      </span>
                    </Button>
                </div>

              </div>

              <div className="ml-10 flex items-center">

                {/* Search */}
                <div className="flex lg:ml-6">
                  <p className="p-2 text-gray-600 hover:text-gray-400">
                    <span className="sr-only">Search</span>
                    <MagnifyingGlassIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </p>
                </div>

                

                {/* Cart */}
                <div className="ml-4 flow-root lg:ml-6">
                  <Avatar
                  sx={{
                    bgcolor: "gray-200",
                    color: "white",
                    cursor: "pointer",
                  }}
                  >

                    <Button
                      onClick={() => navigate("/cart")}
                      className="group -m-2 flex items-center p-2"
                    >
                      <AddShoppingCartIcon
                        className="h-6 w-6 flex-shrink-0 text-gray-800 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span className="sr-only">items in cart, view bag</span>
                    </Button>

                  </Avatar>
                </div>

                {/* Sign In */}
                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    {auth.user?.firstName ? (
                        <div className="ml-6">
                          <Avatar className="text-white" 
                           onClick={handleUserClick}
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          sx={{
                            bgcolor: deepPurple[500],
                            color: "white",
                            cursor: "pointer",
                          }}
                          >
                            {auth.user?.firstName[0].toUpperCase()}
                          </Avatar>

                          <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openUserMenu}
                          onClose={handleCloseUserMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          >

                            <MenuItem onClick={handleCloseUserMenu}>
                                Profile
                            </MenuItem>

                            <MenuItem onClick={handleMyOrderClick}>
                                My Orders
                            </MenuItem>

                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            
                          </Menu>
                        </div>
                    ) : (
                      <Button onClick={handleOpen} className="ml-4 text-sm font-medium text-gray-700 hover:text-gray-800">
                          Signin
                      </Button>
                    )}
                  
                </div>

              </div>
            </div>
            <div className="flex items-center justify-center rounded-lg mt-3 mb-2 bg-gray-300 pt-5 pb-5">
              {/* Flyout menus */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch z-10">
                <div className="flex h-full space-x-20">
                  {navigation.categories.map((category) => (
                    <Popover key={category.name} className="flex">
                      {({ open, close }) => (
                        <>
                          <div className="relative flex">
                            <Popover.Button
                              className={classNames(
                                open
                                  ? "border-indigo-600 text-indigo-600"
                                  : "border-transparent text-gray-700 hover:text-gray-800",
                                "relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out"
                              )}
                            >
                              {category.name}
                            </Popover.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Popover.Panel className="absolute inset-x-0 top-full text-sm text-gray-500">
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div
                                className="absolute inset-0 top-1/2 bg-white shadow"
                                aria-hidden="true"
                              />

                              <div className="relative bg-white">
                                <div className="mx-auto max-w-7xl px-8">
                                  <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-16">
                                    <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                      {category.featured.map((item) => (
                                        <div
                                          key={item.name}
                                          className="group relative text-base sm:text-sm"
                                        >
                                          <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                            <img
                                              src={item.imageSrc}
                                              alt={item.imageAlt}
                                              className="object-cover object-center"
                                            />
                                          </div>
                                          <a
                                            href={item.href}
                                            className="mt-6 block font-medium text-gray-900"
                                          >
                                            <span
                                              className="absolute inset-0 z-10"
                                              aria-hidden="true"
                                            />
                                            {item.name}
                                          </a>
                                          <p
                                            aria-hidden="true"
                                            className="mt-1"
                                          >
                                            Shop now
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="row-start-1 grid grid-cols-3 gap-x-8 gap-y-10 text-sm">
                                      {category.sections.map((section) => (
                                        <div key={section.name}>
                                          <p
                                            id={`${section.name}-heading`}
                                            className="font-medium text-gray-900"
                                          >
                                            {section.name}
                                          </p>
                                          {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                                          <ul
                                            role="list"
                                            aria-labelledby={`${section.name}-heading`}
                                            className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                          >
                                            {section.items.map((item) => (
                                              <li
                                                key={item.name}
                                                className="flex"
                                              >
                                                <p
                                                  onClick={() =>
                                                    handleCategoryClick(
                                                      category,
                                                      section,
                                                      item,
                                                      close
                                                    )
                                                  }
                                                  className="cursor-pointer hover:text-gray-800"
                                                >
                                                  {item.name}
                                                </p>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  ))}

                  {/* {navigation.pages.map((page) => (
                    <a
                      key={page.name}
                      href={page.href}
                      className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                    >
                      {page.name}
                    </a>
                  ))} */}
                </div>
              </Popover.Group>
            </div>
          </div>
        </nav>
      </header>
      <AuthModal handleClose={handleClose} open={openAuthModal} />
    </div>
  );
}
