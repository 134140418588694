import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import HomePage from '../home/pages/homepage/HomePage'
import Cart from '../home/components/cart/Cart'
import Navigation from '../home/components/navigation/Navigation'
import Footer from '../home/components/footer/Footer'
import Product from '../home/components/product/Product'
import ProductDetails from '../home/components/productdetails/ProductDetails'
import Checkout from '../home/components/checkout/Checkout'
import Order from '../home/components/order/Order'
import OrderDetails from '../home/components/order/OrderDetails'
import PaymentSuccess from '../home/components/paymentSuccess/PaymentSuccess'
import BrandsPage from '../Pages/Brands/Brands'
import BookDemo from '../home/components/bookDemo/bookDemo'
import LoginUserForm from '../home/components/auth/Login'

const CustomerRoutes = () => {

  const location = useLocation();
    
  const showNavigation = location.pathname !== "*";

  return (
    <div>

        {showNavigation && <Navigation/>}

        <Routes>

            <Route path='/' element={<HomePage/>}></Route>

            <Route path='/login' element={<HomePage/>}></Route>

            <Route path='/register' element={<HomePage/>}></Route>

            <Route path='/cart' element={<Cart/>}></Route>

            <Route path='/:lavelOne/:lavelTwo/:lavelThree' element={<Product/>}></Route>

            <Route path='/product/:productId' element={<ProductDetails/>}></Route>

            <Route path='/checkout' element={<Checkout />}></Route>

            <Route path='/account/order' element={<Order/>}></Route>

            <Route path='/account/order/:orderId' element={<OrderDetails/>}></Route>

            <Route path='/payment/:orderId' element={<PaymentSuccess/>}></Route>

            <Route path='/brands' element={<BrandsPage/>}></Route>

            <Route path='/book/demo' element={<BookDemo/>}></Route>

        </Routes>

        <Footer/>

    </div>
  )
}

export default CustomerRoutes